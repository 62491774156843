var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',{attrs:{"gutter":20}},[(_vm.category.id == 21 || _vm.category.id == 19)?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Habilitar envio de notificação e e-mail\n      ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `allow_send_notification_and_email`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `allow_send_notification_and_email`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"}},_vm._l((_vm.allowSendEmail),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"txt":item.label}},[_vm._v("\n          "+_vm._s(item.label)+"\n        ")])}),1)],1)],1):_vm._e(),_vm._v(" "),(
      _vm.form.getFieldValue(`name`) &&
      _vm.form.getFieldValue(`allow_send_notification_and_email`) == 'Sim'
    )?_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v("Setor Responsável ("+_vm._s(_vm.form.getFieldValue(`name`))+")")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `responsible_user_role`,
          {
            rules: [
              {
                required: false,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `responsible_user_role`,\n          {\n            rules: [\n              {\n                required: false,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione (opcional)","optionFilterProp":"txt"}},[_vm._l((_vm.roles),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"txt":item.label}},[_vm._v("\n          "+_vm._s(item.name)+"\n        ")])}),_vm._v(" "),_c('a-select-option',{attrs:{"value":'Nenhum',"txt":'Nenhum'}},[_vm._v("\n          Nenhum\n        ")])],2)],1)],1):_vm._e(),_vm._v(" "),(
      (_vm.category.id == 21 &&
        _vm.form.getFieldValue(`responsible_user_role`) == 'admin') ||
      (_vm.category.id == 19 &&
        _vm.form.getFieldValue(`responsible_user_role`) == 'admin')
    )?_c('a-col',{staticClass:"mb-30",attrs:{"span":24}},[_c('a-alert',{staticClass:"f12",attrs:{"type":"info","message":"Observação: Administradores já recebem notificação por padrão.","banner":""}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }