<template>
  <aRow :gutter="20">
    <a-col v-if="category.id == 21 || category.id == 19" :span="12">
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"
          >Habilitar envio de notificação e e-mail
        </label>

        <a-select
          class="travel-input"
          placeholder="Selecione"
          optionFilterProp="txt"
          v-decorator="[
            `allow_send_notification_and_email`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          style="width: 100%"
        >
          <a-select-option
            v-for="(item, index) of allowSendEmail"
            :key="index"
            :value="item.value"
            :txt="item.label"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col
      v-if="
        form.getFieldValue(`name`) &&
        form.getFieldValue(`allow_send_notification_and_email`) == 'Sim'
      "
      :span="12"
    >
      <a-form-item class="travel-input-outer">
        <label for :class="'filled'"
          >Setor Responsável ({{ form.getFieldValue(`name`) }})</label
        >
        <a-select
          class="travel-input"
          placeholder="Selecione (opcional)"
          optionFilterProp="txt"
          v-decorator="[
            `responsible_user_role`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]"
          style="width: 100%"
        >
          <a-select-option
            v-for="(item, index) of roles"
            :key="index"
            :value="item.value"
            :txt="item.label"
          >
            {{ item.name }}
          </a-select-option>
          <a-select-option :value="'Nenhum'" :txt="'Nenhum'">
            Nenhum
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col
      v-if="
        (category.id == 21 &&
          form.getFieldValue(`responsible_user_role`) == 'admin') ||
        (category.id == 19 &&
          form.getFieldValue(`responsible_user_role`) == 'admin')
      "
      class="mb-30"
      :span="24"
    >
      <a-alert
        class="f12"
        type="info"
        message="Observação: Administradores já recebem notificação por padrão."
        banner
      />
    </a-col>
  </aRow>
</template>

<script>
// json
import roles from "@/json/roles.json";

export default {
  name: "CategoryEntryNotesCustomFields",
  props: {
    form: Object,
    category: Object,
  },
  data() {
    return {
      roles,
      allowSendEmail: [
        { label: "Sim", value: "Sim" },
        { label: "Não", value: "Não" },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass" scoped></style>
